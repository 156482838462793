.welcome-design {
  width: 45%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .app-name {
    height: fit-content;
    font-style: normal;
    font-weight: 600;
    font-size: 3.2rem;
    color: #ffffff;
  }

  .app-image-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;

    .app-image {
      height: 100%;
      width: 100%;
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .welcome-design {
    height: calc(0.54 * 100vh);
    width: 90%;
    .app-name {
      margin-top: 20px;
    }
  }
}
