.PageHeader {
  margin-bottom: 25px;
  font-size: 2.5rem;
  font-weight: 600;
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .PageHeader {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }
}
