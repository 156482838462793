.ProgressIndicator {
  position: relative;
  height: 5rem;
  width: 50px;

  .inner {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    height: 4rem;
    width: 40px;
    margin: -20px 0 0 -20px;
    border-radius: 100%;
  }

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--primary-font-color);
  }

  .circle {
    .bar {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      clip: rect(0px, 50px, 50px, 25px);

      .progress {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        clip: rect(0px, 25px, 50px, 0px);
        background: var(--progress-color);
      }
    }

    .left .progress {
      z-index: 1;
    }

    .right {
      transform: rotate(180deg);
      z-index: 3;
    }

    .inactive {
      display: none;
    }
  }
}
