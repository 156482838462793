.Footer {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;

  .footer-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 134px;
    padding: 24px;
    background-color: var(--page-content-background-color);

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .app-logo :hover {
        cursor: pointer;
      }
    }

    .links {
      display: flex;
      align-items: center;
      justify-content: center;

      .footer-link {
        margin: 0 12px;
        text-decoration: none;
        color: inherit;
      }

      div:hover {
        color: var(--primary-font-link-color);
      }
    }
  }

  .footer-bottom {
    height: 57px;
    width: 100%;
    background-color: var(--standard-layout-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .Footer {
    font-size: 1.4rem;

    .footer-top {
      height: fit-content;
      padding: 16px;

      .links {
        justify-content: space-between;
        flex-direction: column;

        div {
          margin: 5px 0;
        }

        div:last-child {
          margin-bottom: 0;
        }

        div:hover {
          cursor: pointer;
        }
      }
    }
  }
}
