.BatchCard {
  height: 181px;
  border-radius: 8px;
  padding: 16px;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--over-button-font-color);
  text-decoration: none;

  .batch-details-wrapper {
    min-height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name {
      font-weight: 600;
      line-height: 2.3rem;
      font-size: 1.8rem;
      hyphens: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 4px 0;
    }

    .stream-name {
      display: flex;
      align-items: center;
      padding: 8px;
      border-radius: 8px;
      width: fit-content;
      box-shadow: 0px 2px 40px rgba(120, 120, 120, 0.071323);
      opacity: 1;
      height: 3.6rem;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 4px 0;
      hyphens: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .no-of-students {
      display: flex;
      align-items: center;

      .icon-wrapper {
        display: flex;
        align-items: center;
        padding-right: 10px;
      }
    }

    line-height: 2.3rem;
  }

  .date-wrapper {
    font-size: 1.2rem;
  }
}

.BatchCard:hover {
  cursor: pointer;
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .BatchCard {
    font-size: 1.2rem;
    height: 125px;
    padding: 10px 16px;

    .batch-details-wrapper {
      .name {
        font-weight: 600;
        line-height: 2.3rem;
        font-size: 1.4rem;
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }

      .stream-name {
        padding: 5px 10px;
        height: 3rem;
      }

      .no-of-students {
        display: none;
      }

      line-height: 1.8rem;
    }
  }
}
