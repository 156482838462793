.ContactUs {
  width: 100%;
  height: 100%;
  background: var(--page-content-background-color);
  border-radius: 6px;
  padding: 3%;

  .contactus-message-wrapper {
    width: 100%;
    height: 60%;
    display: flex;
    padding-top: 1%;

    .contact-message {
      color: var(--secondary-font-color);
      padding: 2.4rem;
      border-radius: 1.2rem;
      margin-inline: auto;
      text-align: center;
      p {
        margin-bottom: 0;
      }
    }
  }

  .ok-button-wrapper {
    height: 40%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
