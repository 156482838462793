.user-organization {
  right: 12%;
  .loading-wrapper {
    border-radius: 12px;
    background-color: var(--page-content-background-color);
    margin-top: -40px;
    padding: 12px 0px;
  }
  .demo-wrapper {
    border-radius: 12px;
    background-color: var(--page-content-background-color);
    .section {
      border-bottom: 1px solid var(--app-background-color);
      padding: 6px 0;
      .section-head {
        padding: 0 24px;
        font-size: 1.6rem;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .current-org {
      .section-head {
        padding-top: 24px;
      }
    }
    .switch-org {
      .all-organization-wrapper {
        max-height: 200px;
        height: fit-content;
        overflow: scroll;
      }
    }

    .dropdown-actions {
      display: flex;
      align-items: center;
      padding: 24px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      max-height: 5rem;
      text-decoration: none;
      color: inherit;
    }
    .dropdown-actions:hover {
      cursor: pointer;
      background-color: var(--options-hover-background-color);
    }
  }
  .organization-card {
    display: flex;
    align-items: center;
    padding: 12px;
    width: 100%;
    text-decoration: none;
    color: inherit;

    .organization-image {
      width: 30%;
      min-width: 30%;
      height: 3rem;
      margin-right: 1rem;
      border-radius: 0.6rem;
      opacity: 0.8;
      border: 1px solid rgba($color: #000000, $alpha: 0.15);
    }
  }
  .organization-card:hover {
    cursor: pointer;
    background-color: var(--options-hover-background-color);
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .user-organization {
    right: 0%;
    .demo-wrapper {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      height: fit-content;

      .section {
        .section-head {
          font-size: 1.4rem;
          padding-top: 12px;
        }
      }
    }
  }
}
