.StandardLayout {
  width: 100vw;
  background-color: var(--standard-layout-background-color);

  .page-content-wrapper {
    padding: 0px 7%;

    .page-content {
      max-width: 1200px;
      margin-inline: auto;
      padding: 40px 0;
      min-height: calc(100vh - 80px);
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .StandardLayout {
    .page-content-wrapper {
      .page-content {
        padding: 16px 0;
      }
    }
  }
}
