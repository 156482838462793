.courseInPathCardWrapper {
  width: 100%;
  background-color: var(--options-hover-background-color);
  border: 1px solid var(--update-button-border-color);
  padding: 16px;
  border-radius: 12px;
  position: relative;

  .courseInPathcard {
    display: flex;
    width: 100%;

    .course-in-path-card-left {
      width: 25%;

      img {
        width: 100%;
        aspect-ratio: 2;
        border-radius: 12px;
      }

      :hover {
        cursor: pointer;
      }
    }

    .course-in-path-card-middle {
      height: 100%;
      width: 65%;
      color: var(--secondary-font-color);
      font-size: 1.6rem;
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .course-name-link {
        margin-top: 0;
        font-weight: 500;
        line-height: 2.4rem;
        margin-bottom: 3px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-decoration: none;
        color: inherit;
        hyphens: auto;
      }

      div {
        width: 100%;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.2rem;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        hyphens: auto;
      }
    }
  }

  .mobile-course-in-path-card-description {
    width: 100%;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    hyphens: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    display: none;
  }

  .restriction-wrapper {
    border-radius: 12px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;

    .bottom-message {
      color: var(--over-button-font-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      width: 100%;
      height: 3rem;
      background-color: var(--course-inaccessible-banner-color);
    }
  }

  .not-accessible {
    cursor: not-allowed;
    background-color: rgba($color: #000000, $alpha: 0.4);
    align-items: flex-end;
  }

  .not-published {
    align-items: center;
    overflow: hidden;

    .bottom-message {
      border-radius: 0;
      margin-left: -80px;
      margin-top: -40px;
      width: 250px;
      font-size: 1.4rem;
      rotate: -45deg;
      overflow: hidden;
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .courseInPathCardWrapper {
    .courseInPathcard {
      .course-in-path-card-left {
        width: 45%;
      }
      .course-in-path-card-middle {
        height: 110px;
        width: 55%;

        .course-name-link {
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 2rem;
          font-size: 1.4rem;
        }

        div {
          font-size: 1rem;
        }

        .course-card-description {
          display: none;
        }
      }
    }

    .mobile-course-in-path-card-description {
      display: -webkit-box;
    }
  }
}
