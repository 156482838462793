.light {
  font-size: 1.6rem;
  color: black;
  --student-card-background-color: #fff;
  --app-background-color: #f3f5f9;
  --standard-layout-background-color: #f8f5ff;
  --page-content-background-color: #ffffff;
  --page-heading-font-color: #5624d0;
  --primary-font-color: black;
  --secondary-font-color: #222222;
  --subheading-font-color: rgba(34, 34, 34, 0.8);
  --tertiary-font-color: #b8b8b8;
  --header-icons-background-color: #f3f5f9;
  --over-button-font-color: #ffffff;
  --primary-font-link-color: #5624d0;
  --favorite-heart-color: #f75a58;
  --course-inaccessible-banner-color: #f75a58;
  --progress-color: #8ed000;
  --delete-button-border-color: #f75a5b;
  --delete-button-text-color: #f75a5b;
  --update-button-background-color: #5624d0;
  --update-button-border-color: #5624d0;
  --input-box-focus-border-color: #5624d0;
  --input-box-unfocus-border-color: #d7dae1;
  --input-box-background-color: white;
  --options-hover-background-color: #f9f9f9;
  --search-bar-background-color: white;
  --add-skills-background-color: rgba(86, 36, 208, 0.2);
  --header-background-color: #ffffff;
  --admin-top-banner-background-color: #5624d0;
  --user-card-hover-shadow-color: #9e91c0;
  --user-card-border-color: #dddcdc;
  --quiz-pass-background-color: #e8f2ee;
  --quiz-pass-font-color: #237e52;
  --quiz-fail-background-color: #ffd1cf;
  --quiz-fail-font-color: #ff2d2d;
  --quiz-notattempted-background-color: #fdf8e5;
  --quiz-notattempted-font-color: #7c6918;
  --quiz-awaited-background-color: #d3d3d3;
  --quiz-awaited-font-color: #585858;
  --scroll-bar-color: rgba(40, 13, 81, 0.2);
  --path-in-batch-bg-color: #ffffff;
  --path-card-in-batch-bg-color: #f9f9f9;
  --show-path-card-in-batch-bg-color: #f9f9f9;
  --primary-color: #fff;
  --footer-bottom-color: #e9e0ff;
  --border-color: #f2f2f2;
  --capsule-color: white;
  --counter-color: rgba(113, 102, 238, 0.201709);
  --image-border-color: #e2e4e7;
  --email-card-bg-color: #f5f5f5;
}

.dark {
  font-size: 1.6rem;
  color: white;
  --student-card-background-color: #1c1d1f;
  --app-background-color: #010101;
  --standard-layout-background-color: #010101;
  --page-content-background-color: #222222;
  --page-heading-font-color: #986eff;
  --primary-font-color: white;
  --secondary-font-color: #ffffff;
  --subheading-font-color: rgba(34, 34, 34, 0.8);
  --tertiary-font-color: #4c4c4c;
  --header-icons-background-color: #222222;
  --over-button-font-color: #ffffff;
  --primary-font-link-color: #986eff;
  --favorite-heart-color: #f75a58;
  --course-inaccessible-banner-color: #f75a58;
  --progress-color: #dcff91;
  --delete-button-border-color: #f75a5b;
  --delete-button-text-color: #f75a5b;
  --update-button-background-color: #986eff;
  --update-button-border-color: #986eff;
  --input-box-focus-border-color: #986eff;
  --input-box-unfocus-border-color: #0c0d10;
  --input-box-background-color: #000000;
  --options-hover-background-color: #040404;
  --search-bar-background-color: #040404;
  --add-skills-background-color: #986eff;
  --header-background-color: #010101;
  --admin-top-banner-background-color: #1c1d1f;
  --user-card-hover-shadow-color: #585858;
  --user-card-border-color: #424242;
  --quiz-pass-background-color: #e8f2ee;
  --quiz-pass-font-color: #237e52;
  --quiz-fail-background-color: #ffd1cf;
  --quiz-fail-font-color: #ff2d2d;
  --quiz-notattempted-background-color: #fdf8e5;
  --quiz-notattempted-font-color: #7c6918;
  --quiz-awaited-background-color: #efefef;
  --quiz-awaited-font-color: #585858;
  --scroll-bar-color: #585858;
  --path-in-batch-bg-color: #010101;
  --path-card-in-batch-bg-color: #222222;
  --show-path-card-in-batch-bg-color: #010101;
  --primary-color: #010101;
  --footer-bottom-color: #010101;
  --border-color: #010101;
  --capsule-color: #010101;
  --counter-color: #1c1d1f;
  --image-border-color: #010101;
  --email-card-bg-color: #010101;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-color);
}

.popup-control-button {
  border-radius: 8px;
  width: 116px;
  height: 4.8rem;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.popup-update-button {
  background-color: var(--page-heading-font-color);
  border: 1px solid var(--page-heading-font-color);
  color: var(--over-button-font-color);
}

.popup-remove-button {
  border: 1px solid var(--delete-button-border-color);
  color: var(--delete-button-text-color);
  background-color: var(--delete-button-background-color);
}

.popup-remove-button:hover {
  background-color: var(--delete-button-border-color);
  color: #ffffff;
}

.popup-main-heading {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.popup-sub-heading {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 12px;
}

.popup-count {
  color: var(--page-heading-font-color);
  font-size: 1.4rem;
}

.limit-text-to-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit-text-to-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.popup-InputTag {
  border: var(--input-box-unfocus-border-color) solid 0.5px;
  font-size: 1.6rem;
  padding: 10px;
  border-radius: 6px;
  font-weight: 400;
  background-color: var(--input-box-background-color);
  color: var(--secondary-font-color);
}

.popup-InputTag:focus {
  outline: none !important;
  border: 0.5px solid var(--page-heading-font-color);
}

.background-disable {
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.popup {
  width: 30%;
  max-width: 500px;
  min-width: 300px;
  min-height: 150px;
  height: fit-content;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .light {
    font-size: 1.4rem;
  }

  .dark {
    font-size: 1.4rem;
  }

  .popup-control-button {
    width: 100px;
    height: 4rem;
    font-size: 1.4rem;
  }

  .popup-main-heading {
    font-size: 2.2rem;
    margin-bottom: 8px;
  }
  .popup-sub-heading {
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
  .popup-count {
    font-size: 1.2rem;
  }
  .popup-InputTag {
    font-size: 1.2rem;
    padding: 6px;
  }
}
