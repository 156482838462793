.validation-page {
  .contact-us-wrapper {
    padding: 10%;
    text-align: center;
    .heading {
      font-size: 4rem;
      font-weight: 600;
      margin-bottom: 4rem;
    }
    .contact-message {
      padding: 2.4rem;
      border-radius: 1.2rem;
      background-color: rgba(248, 245, 255, 1);
      color: black;
      width: 80%;
      margin-inline: auto;
      p {
        margin-bottom: 0;
      }
    }
    .logout-btn {
      margin-top: 4rem;
      padding: 0.5rem;
      width: 100px;
      border-radius: 0.6rem;
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .validation-page {
    .contact-us-wrapper {
      .heading {
        font-size: 2rem;
        margin-bottom: 2rem;
      }
      .contact-message {
        padding: 1.2rem;
        width: 100%;
      }
    }
  }
}
