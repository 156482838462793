.App {
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

.light {
  font-size: 1.6rem;
  color: black;
  --student-card-background-color: #fff;
  --app-background-color: #f3f5f9;
  --standard-layout-background-color: #f8f5ff;
  --page-content-background-color: #ffffff;
  --page-heading-font-color: #5624d0;
  --primary-font-color: black;
  --secondary-font-color: #222222;
  --subheading-font-color: rgba(34, 34, 34, 0.8);
  --tertiary-font-color: #b8b8b8;
  --header-icons-background-color: #f3f5f9;
  --over-button-font-color: #ffffff;
  --primary-font-link-color: #5624d0;
  --favorite-heart-color: #f75a58;
  --course-inaccessible-banner-color: #f75a58;
  --progress-color: #8ed000;
  --delete-button-border-color: #f75a5b;
  --delete-button-text-color: #f75a5b;
  --update-button-background-color: #5624d0;
  --update-button-border-color: #5624d0;
  --input-box-focus-border-color: #5624d0;
  --input-box-unfocus-border-color: #d7dae1;
  --input-box-background-color: white;
  --options-hover-background-color: #f9f9f9;
  --search-bar-background-color: white;
  --filter-icons-background-color: #ffffff;
  --add-skills-background-color: rgba(86, 36, 208, 0.2);
  --header-background-color: #ffffff;
  --admin-top-banner-background-color: #5624d0;
  --user-card-hover-shadow-color: #9e91c0;
  --user-card-border-color: #dddcdc;
  --quiz-pass-background-color: #e8f2ee;
  --quiz-pass-font-color: #237e52;
  --quiz-fail-background-color: #ffd1cf;
  --quiz-fail-font-color: #ff2d2d;
  --quiz-notattempted-background-color: #fdf8e5;
  --quiz-notattempted-font-color: #7c6918;
  --quiz-awaited-background-color: #d3d3d3;
  --quiz-awaited-font-color: #585858;
  --scroll-bar-color: rgba(40, 13, 81, 0.2);
  --path-in-batch-bg-color: #ffffff;
  --path-card-in-batch-bg-color: #f9f9f9;
  --show-path-card-in-batch-bg-color: #f9f9f9;
  --primary-color: #fff;
  --footer-bottom-color: #e9e0ff;
  --border-color: #f2f2f2;
  --capsule-color: white;
  --counter-color: rgba(113, 102, 238, 0.201709);
  --image-border-color: #e2e4e7;
  --email-card-bg-color: #f5f5f5;
}

.dark {
  font-size: 1.6rem;
  color: white;
  --student-card-background-color: #1c1d1f;
  --app-background-color: #010101;
  --standard-layout-background-color: #010101;
  --page-content-background-color: #222222;
  --page-heading-font-color: #986eff;
  --primary-font-color: white;
  --secondary-font-color: #ffffff;
  --subheading-font-color: rgba(34, 34, 34, 0.8);
  --tertiary-font-color: #4c4c4c;
  --header-icons-background-color: #222222;
  --over-button-font-color: #ffffff;
  --primary-font-link-color: #986eff;
  --favorite-heart-color: #f75a58;
  --course-inaccessible-banner-color: #f75a58;
  --progress-color: #dcff91;
  --delete-button-border-color: #f75a5b;
  --delete-button-text-color: #f75a5b;
  --update-button-background-color: #986eff;
  --update-button-border-color: #986eff;
  --input-box-focus-border-color: #986eff;
  --input-box-unfocus-border-color: #0c0d10;
  --input-box-background-color: #000000;
  --options-hover-background-color: #040404;
  --filter-icons-background-color: #1c1d1f;
  --search-bar-background-color: #040404;
  --add-skills-background-color: #986eff;
  --header-background-color: #010101;
  --admin-top-banner-background-color: #1c1d1f;
  --user-card-hover-shadow-color: #585858;
  --user-card-border-color: #424242;
  --quiz-pass-background-color: #e8f2ee;
  --quiz-pass-font-color: #237e52;
  --quiz-fail-background-color: #ffd1cf;
  --quiz-fail-font-color: #ff2d2d;
  --quiz-notattempted-background-color: #fdf8e5;
  --quiz-notattempted-font-color: #7c6918;
  --quiz-awaited-background-color: #efefef;
  --quiz-awaited-font-color: #585858;
  --scroll-bar-color: #585858;
  --path-in-batch-bg-color: #010101;
  --path-card-in-batch-bg-color: #222222;
  --show-path-card-in-batch-bg-color: #010101;
  --primary-color: #010101;
  --footer-bottom-color: #010101;
  --border-color: #010101;
  --capsule-color: #010101;
  --counter-color: #1c1d1f;
  --image-border-color: #010101;
  --email-card-bg-color: #010101;
}

::-webkit-scrollbar {
  width: 5px;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-color);
}

.control-button {
  border-radius: 8px;
  width: 153px;
  height: 4.8rem;
  cursor: pointer;
  font-weight: 400;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.update-button {
  background-color: var(--page-heading-font-color);
  border: 1px solid var(--page-heading-font-color);
  color: var(--over-button-font-color);
}

.remove-button {
  border: 1px solid var(--delete-button-border-color);
  color: var(--delete-button-text-color);
  background-color: var(--delete-button-background-color);
}

.remove-button:hover {
  background-color: var(--delete-button-border-color);
  color: #ffffff;
}

.banner-strip {
  width: 100vw;
  background-color: var(--admin-top-banner-background-color);
  padding: 2.5rem 7%;
}

.banner-layout-header {
  width: 100%;
  margin-inline: auto;
  max-width: 1200px;
  color: #ffffff;
  font-size: 3.2rem;
  font-weight: 700;
}

.banner-layout-body-wrapper {
  padding: 40px 7%;
}

.banner-layout-body {
  max-width: 1200px;
  margin-inline: auto;
  min-height: calc(100vh - 80px);
}

.main-heading {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.sub-heading {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 12px;
}

.link-as-normal-text {
  text-decoration: none;
  color: var(--secondary-font-color);
}

.count {
  color: var(--page-heading-font-color);
  font-size: 1.4rem;
}

.limit-text-to-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.limit-text-to-2-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.InputTag {
  border: var(--input-box-unfocus-border-color) solid 0.5px;
  font-size: 1.6rem;
  padding: 10px;
  border-radius: 6px;
  font-weight: 400;
  background-color: var(--input-box-background-color);
  color: var(--secondary-font-color);
}

.InputTag:focus {
  outline: none !important;
  border: 0.5px solid var(--page-heading-font-color);
}

button:hover {
  cursor: pointer;
}

.welcome-page {
  background: linear-gradient(to right, #5624d0, #8b24bb);
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.org-btn {
  margin: 24px;
  min-height: 48px;
  height: 48px;
  min-width: fit-content;
  width: 50%;
  font-size: 1.6rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(86, 36, 208, 1);
}

.dropdown {
  overflow: hidden;
  cursor: default;
  text-overflow: ellipsis;
  width: 375px;
  height: fit-content;
  z-index: 1000;
  position: absolute;
  top: 60px;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  box-shadow: 0px 16px 34px -9px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

img {
  border: 1px solid var(--image-border-color);
}

@media only screen and (orientation: portrait),
only screen and (max-width: 768px) {
  .light {
    font-size: 1.4rem;
  }

  .dark {
    font-size: 1.4rem;
  }

  .control-button {
    width: 140px;
    height: 4rem;
    font-size: 1.4rem;
  }

  .banner-strip {
    padding: 1.6rem 7%;
  }

  .banner-layout-header {
    font-size: 2.5rem;
  }

  .main-heading {
    font-size: 2rem;
    margin-bottom: 8px;
  }

  .banner-layout-body-wrapper {
    padding: 20px 7%;
  }

  .sub-heading {
    font-size: 1.6rem;
    margin-bottom: 8px;
  }

  .count {
    font-size: 1.2rem;
  }

  .InputTag {
    font-size: 1.2rem;
    padding: 6px;
  }

  .welcome-page {
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: auto;
  }

  .org-btn {
    margin: 20px;
    min-height: 32px;
    height: 32px;
    width: 70%;
    border-radius: 6px;
  }

  .dropdown {
    width: 100vw;
    height: 100vh;
    right: 0px;
    top: 50px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}