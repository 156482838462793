.admin-Stream-card {
  width: 100%;
  height: 165px;
  border-radius: 8px;
  position: relative;
  text-decoration: none;
  display: inline-block;

  .stream-card-background {
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;

    .circle {
      height: 126px;
      width: 126px;
      border-radius: 50%;
      opacity: 0.1;
      background-color: white;
    }

    .background-top {
      height: 50%;
      overflow: hidden;
    }

    .background-bottom {
      height: 50%;
      overflow: hidden;
    }
  }

  .stream-card-foreground {
    width: 100%;
    height: 100%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .stream-name {
      hyphens: auto;
      overflow: hidden;
      color: var(--over-button-font-color);
      font-size: 3rem;
      font-weight: 600;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.admin-Stream-card:hover {
  cursor: pointer;
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .admin-Stream-card {
    height: 100px;

    .stream-card-foreground {
      padding: 10px;

      .stream-name {
        font-size: 1.6rem;
        font-weight: 900;
      }
    }
  }
}
