.Header {
  user-select: none;
  padding: 0 7%;
  display: flex;
  align-items: center;
  height: 64px;
  background-color: var(--header-background-color);
  font-size: 2.2rem;
  position: sticky;
  top: 0;
  z-index: 100;

  .org-logo-link,
  .logout-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  .header-links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 48px;

    #mobile-search-icon {
      display: none;
      justify-content: center;
      align-items: center;
      background-color: var(--header-icons-background-color);
    }

    .icon,
    .user-wrapper {
      background-color: var(--header-icons-background-color);
      height: 100%;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1%;
      font-size: 2.4rem;
      border-radius: 6px;
      cursor: pointer;

      .profile-image {
        border-radius: 6px;
        height: 100%;
        width: 100%;
      }

      .disabled-button {
        pointer-events: none;
        opacity: 0;
      }
    }

    .disabled-button {
      pointer-events: none;
      opacity: 0;
    }

    .mobile-search-bar-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--header-background-color);

      .left-arrow-wrapper {
        .left-arrow {
          display: flex;
          height: 100%;

          :hover {
            cursor: pointer;
          }
        }
      }

      .mobile-search-bar {
        display: flex;
        align-items: center;
        background-color: var(--header-icons-background-color);
        height: 4rem;
        width: 80%;
        margin: 0 12px;
        padding: 0 10px;
        border-radius: 6px;

        .search-input {
          font-size: 1.6rem;
          flex-grow: 1;
          border: none;
          background-color: var(--header-icons-background-color);
          width: 90%;
          color: var(--secondary-font-color);
        }

        input:focus {
          outline: none;
        }

        .search-icon {
          padding-right: 10px;
          font-size: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .my-search-icon {
            height: 100%;
            display: flex;
            align-items: center;
          }
        }

        .cross-icon {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;

          .cross-icon-svg {
            height: 100%;
            display: flex;
            align-items: center;
          }

          .cross-icon-svg:hover {
            cursor: pointer;
          }
        }
      }
    }

    .searchbar {
      display: flex;
      align-items: center;
      background-color: var(--header-icons-background-color);
      height: 4.8rem;
      width: 62%;
      margin: 0 12px;
      padding: 0 10px;
      border-radius: 6px;

      .search-input {
        font-size: 2rem;
        flex-grow: 1;
        border: none;
        background-color: var(--header-icons-background-color);
        width: 90%;
        color: var(--secondary-font-color);
      }

      input:focus {
        outline: none;
      }

      .search-icon {
        cursor: pointer;
        display: flex;
      }
    }

    .org-wrapper {
      height: 100%;

      .logo-wrapper {
        background-color: var(--header-icons-background-color);
        display: flex;
        padding: 4px 6px;
        border-radius: 6px;
        height: 100%;

        .org-image {
          border-radius: 6px;
          height: 100%;
          width: 100px;
          border: 1px solid var(--border-color);
        }

        .expand-wrapper {
          padding-left: 6px;
        }
      }
    }

    .org-wrapper:hover {
      cursor: pointer;
    }
  }
}

@media only screen and (orientation: portrait),
only screen and (max-width: 768px) {
  .Header {
    width: 100%;
    height: 5.4rem;
    justify-content: space-between;

    .header-links {
      width: fit-content;

      .user-wrapper {
        margin-left: 20px;
      }

      #upload,
      #wishlist,
      #theme,
      .searchbar,
      #notification {
        display: none;
      }

      #mobile-search-icon {
        display: flex;
      }
    }
  }
}

@media only screen and (orientation: portrait),
only screen and (max-width: 768px) {
  .Header {
    width: 100%;
    height: 5.4rem;
    justify-content: space-between;

    .header-links {
      width: fit-content;
      margin-left: 0;

      .user-wrapper {
        margin-left: 20px;
      }

      #upload,
      #wishlist,
      #theme,
      .searchbar,
      #notification {
        display: none;
      }

      #mobile-search-icon {
        display: flex;
      }

      .org-wrapper {
        height: 100%;
        margin-left: 20px;

        .logo-wrapper {
          background-color: var(--header-icons-background-color);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px 6px;
          border-radius: 6px;
          height: 100%;

          .org-image {
            border-radius: 6px;
            height: 100%;
            width: 50px;
          }

          .expand-wrapper {
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
