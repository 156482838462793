.PathCard {
  background-color: var(--page-content-background-color);
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 8px;
  font-size: 1.6rem;
  border-radius: 16px;
  width: 100%;

  .path-img {
    width: 43.25%;
    height: 100%;
    border-radius: 16px;

    .card-link {
      text-decoration: none;
      color: inherit;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
        object-fit: cover;

        :hover {
          cursor: pointer;
        }
      }
    }
  }

  .path-details {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7px;
    font-size: 1.8rem;

    .name {
      height: 4.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
      line-height: 2.4rem;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      .card-link {
        text-decoration: none;
        color: inherit;
      }
    }

    .description {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .PathCard {
    height: 122px;
    flex-direction: column;
    padding: 8px;
    font-size: 1.2rem;
    border-radius: 12px;
    width: 100%;

    .path-img {
      width: 100%;
      height: 45.9%;
      border-radius: 12px;
    }

    .path-details {
      width: 100%;
      padding: 7px 0;

      .name {
        height: 2.1rem;
        font-size: 1.4rem;
        line-height: 2.1rem;
      }
    }
  }
}
