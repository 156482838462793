.TimelineCourseCard {
  display: flex;
  width: 100%;
  .left-timeline {
    display: flex;
    flex-direction: column;
    align-items: center;

    .timeline-circle {
      height: 1.9rem;
      width: 19px;
      border-radius: 50%;
    }

    .timeline-line {
      width: 2px;
      height: 100%;
    }
  }

  .right-card {
    width: 95%;
    margin-left: 16px;
    margin-bottom: 24px;
  }
}
