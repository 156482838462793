.HomePage {
  overflow: hidden;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100vh;

  .page {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    color: black;
  }

  .page1 {
    position: relative;

    .page1Background {
      height: 120vh;
      width: 120vw;
      border-bottom-left-radius: 20%;
      rotate: 11deg;
      transform: translate(-10vw, -25vh);
      background: linear-gradient(#8f4aea, #5624d0);
    }

    .page1Foreground {
      width: 100%;
      position: absolute;
      top: 0;

      .page1Top {
        padding: 30px 7vw;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page1LogoWrapper {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          height: 5rem;
          width: 110px;

          * {
            height: 100%;
            width: 100%;
          }
        }

        .page1ActionsWrapper {
          width: fit-content;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .contact-us {
            color: #ffffff;
            margin-right: 60px;
            font-size: 1.4rem;
          }

          .contact-us:hover {
            cursor: pointer;
          }

          .get-started {
            background-color: #ffffff;
            font-size: 1.4rem;
            padding: 15px 30px;
            border: none;
            border-radius: 6px;
            display: none;

            .mobile-right-wrapper {
              display: none;
            }
          }

          .get-started:hover {
            cursor: pointer;
          }
        }
      }

      .page1ContentWrapper {
        width: 100%;
        margin-top: 30px;
        display: flex;

        .page1TextHolder {
          width: 50%;
          padding-left: 7vw;
          color: #ffffff;

          .page1Title {
            margin-top: 40px;
            width: 90%;
            font-weight: 600;
            font-size: min(48px, calc(100vw / 27));
          }

          .page1Description {
            width: 90%;
            margin-top: 30px;
            font-size: min(24px, calc(100vw / 48));
          }

          .get-started-button-wrapper {
            margin-top: 80px;
            width: fit-content;
            background-color: #ffffff;
            border-radius: 8px;
            padding: 12px 30px;
            color: #5624d0;
            display: flex;
            align-items: center;

            .get-started-main-text {
              margin-right: 20px;
            }
          }

          .get-started-button-wrapper:hover {
            cursor: pointer;
          }
        }

        .page1SvgHolder {
          width: 50%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .page2 {
    display: flex;
    justify-content: space-between;
    padding: 0px 7vw;

    .page2Item {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20%;

      .svg-holder {
        width: 60%;
        background: linear-gradient(to right, #9d81ff, #5b1d9e);
        aspect-ratio: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }

      .page2ItemTitle {
        margin-top: 15px;
        font-size: min(24px, calc(100vw / 48));
        font-weight: 600;
        text-align: center;
      }

      .page2ItemDescription {
        margin-top: 10px;
        text-align: center;
        font-size: min(16px, calc(100vw / 55));
      }
    }
  }

  .page3 {
    padding: 0 7vw;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page3TextHolder {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .page3Title {
        width: 90%;
        font-weight: 600;
        font-size: min(48px, calc(100vw / 27));
      }

      .page3TitleUnderline {
        height: 1rem;
        border-radius: 10px;
        background-color: #5624d0;
        width: 25%;
      }

      .page3Description {
        margin-top: 15px;
        font-size: min(16px, calc(100vw / 55));
        width: 80%;
      }
    }

    .page3CardsHolder {
      width: 50%;
      position: relative;

      .card-title {
        font-size: 1.4rem;
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .page3Card {
        box-shadow: 8px 8px 8px rgba(black, 0.15);
        height: fit-content;
        border-radius: 12px;
        pointer-events: none;
        width: 100%;
      }

      .page3CardsTopWrapper {
        width: 80%;
        display: flex;
        justify-content: flex-end;
        z-index: 0;

        .stream-card-wrapper {
          width: 60%;
          margin-right: 10%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

      .page3CardsBottomWrapper {
        width: 80%;
        display: flex;
        margin-top: -8%;

        .batch-card-wrapper {
          border-radius: 12px;
          z-index: 1;
          width: 70%;
        }

        .path-card-wrapper {
          width: 65%;
          z-index: 2;
          margin-left: -8%;
          margin-top: 18%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }

  .page4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7vw;

    .page4SvgHolder {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .page4TextHolder {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .page4Title {
        font-weight: 600;
        font-size: min(48px, calc(100vw / 27));
      }

      .page4TitleUnderline {
        background-color: #5624d0;
        border-radius: 10px;
        height: 1rem;
        width: 25%;
      }

      .page4Description {
        width: 80%;
        margin-top: 15px;
        font-size: min(16px, calc(100vw / 55));
      }
    }
  }

  .page5 {
    padding: 0 11vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .page5Title {
      font-weight: 600;
      font-size: min(48px, calc(100vw / 27));
    }

    .page5BottomWrapper {
      margin-top: 40px;
      position: relative;
      height: 45%;
      width: 100%;

      .page5CardsBackgroundWrapper {
        background: linear-gradient(#ffffff, rgba(#8644e6, 0.15));
        height: 100%;
        width: 100%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }

      .page5CardsHolder {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        .course-card-wrapper {
          pointer-events: none;
          border-radius: 12px;
          box-shadow: 8px 8px 8px rgba(black, 0.15);
          width: 30%;
        }
      }
    }
  }

  .page6 {
    display: flex;
    align-items: center;

    .page6LeftHolder {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 7vw;

      .page6Title {
        font-weight: 600;
        font-size: min(48px, calc(100vw / 27));
      }

      .page6TitleUnderline {
        background-color: #5634d0;
        width: 20%;
        height: 1rem;
        border-radius: 10px;
      }

      .page6Description {
        font-size: min(16px, calc(100vw / 55));
        margin-top: 15px;
        width: 65%;
      }

      .page6ItemsHolder {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        flex-wrap: wrap;

        .page6Item {
          width: 45%;
          padding: 10px;
          flex-direction: column;
          align-items: center;

          .page6ItemSvgHolder {
            height: 140px;
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .page6ItemTitle {
            text-align: center;
            font-size: min(24px, calc(100vw / 48));
            font-weight: 600;
            margin-top: 12px;
          }
        }
      }
    }

    .page6SvgHolder {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .page7 {
    position: relative;

    .page7ContentHolder {
      padding: 0 7vw;
      display: flex;
      height: calc(100vh - 130px);
      align-items: center;
      justify-content: space-between;

      .page7SvgHolder {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .page7TextHolder {
        width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .page7Title {
          font-weight: 600;
          font-size: min(48px, calc(100vw / 27));
        }

        .page7TitleUnderline {
          background-color: #5634d0;
          height: 1rem;
          border-radius: 10px;
          width: 25%;
        }

        .page7Description {
          font-size: min(16px, calc(100vw / 55));
          margin-top: 15px;
          width: 80%;
        }
      }
    }

    .footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      .footer-skillup {
        height: 80px;
        width: 100%;
        background-color: #e9e0ff;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .footer-rights {
        font-size: 1.4rem;
        height: 5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .HomePage {
    .page1 {
      .page1Background {
        rotate: 4deg;
        height: 95vh;
      }

      .page1Foreground {
        height: 95vh;

        .page1Top {
          padding-bottom: 15px;

          .page1LogoWrapper {
            display: flex;
            align-items: flex-end;
            height: 4rem;
            width: 80px;

            * {
              height: 100%;
              width: 100%;
            }
          }

          .page1ActionsWrapper {
            align-items: center;

            .get-started {
              display: flex;
              align-items: center;
              background: linear-gradient(#ddcff8, #f4f3f8);
              padding: 10px 20px;
              font-size: 1.6rem;

              .mobile-right-wrapper {
                display: flex;
                width: 20px;
                height: 2rem;
                margin-left: 15px;
              }
            }
          }
        }

        .page1ContentWrapper {
          flex-direction: column;
          align-items: center;
          height: 80%;
          margin-top: 0px;
          justify-content: center;

          .page1TextHolder {
            height: fit-content;
            width: 100%;

            .page1Title {
              margin-top: 25px;
              font-size: 2.4rem;
            }

            .page1Description {
              margin-top: 20px;
              font-size: 1.5rem;
            }

            .get-started-button-wrapper {
              display: none;
            }
          }

          .page1SvgHolder {
            height: 60%;
            width: 90%;

            * {
              height: 100%;
            }
          }
        }
      }
    }

    .page2 {
      display: none;
    }

    .page3 {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .page3TextHolder {
        height: fit-content;
        width: 100%;

        .page3Title {
          font-size: 2rem;
          width: 100%;
        }

        .page3TitleUnderline {
          height: 6px;
          border-radius: 6px;
        }

        .page3Description {
          font-size: 1.4rem;
          width: 95%;
        }
      }

      .page3CardsHolder {
        height: 50%;
        width: 100%;

        .page3CardsTopWrapper {
          width: 100%;
        }

        .page3CardsBottomWrapper {
          width: 100%;
          margin-top: -10%;
        }
      }
    }

    .page4 {
      flex-direction: column;
      align-items: center;

      .page4SvgHolder {
        height: 50%;
        width: 100%;

        * {
          height: 100%;
        }
      }

      .page4TextHolder {
        width: 100%;
        height: 50%;
        justify-content: flex-start;

        .page4Title {
          font-size: 2rem;
          width: 100%;
        }

        .page4TitleUnderline {
          height: 6px;
          border-radius: 6px;
        }

        .page4Description {
          font-size: 1.4rem;
          width: 95%;
        }
      }
    }

    .page5 {
      display: none;
    }

    .page6 {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .page6LeftHolder {
        height: 80%;
        width: 90%;
        padding-left: 0px;
        justify-content: flex-start;

        .page6Title {
          width: 100%;
          font-size: 2rem;
          margin-left: 5%;
        }

        .page6TitleUnderline {
          height: 6px;
          border-radius: 6px;
          margin-left: 5%;
        }

        .page6Description {
          width: 95%;
          margin-top: 10px;
          font-size: 1.4rem;
          margin-left: 5%;
        }

        .page6ItemsHolder {
          margin-top: 12px;
          width: 100%;
          height: 60%;
          align-items: flex-start;

          .page6Item {
            height: 40%;

            .page6ItemSvgHolder {
              height: 90%;

              * {
                height: 100%;
              }
            }

            .page6ItemTitle {
              font-size: 1.4rem;
            }
          }
        }
      }

      .page6SvgHolder {
        display: none;
      }
    }

    .page7 {
      .page7ContentHolder {
        flex-direction: column-reverse;
        justify-content: center;

        .page7TextHolder {
          height: fit-content;
          width: 100%;

          .page7Title {
            font-size: 2rem;
            width: 100%;
          }

          .page7TitleUnderline {
            height: 6px;
            border-radius: 6px;
          }

          .page7Description {
            font-size: 1.4rem;
            width: 95%;
          }
        }

        .page7SvgHolder {
          height: 50%;
          width: 100%;
        }
      }
    }
  }
}
