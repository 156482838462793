.welcome-right-space {
  width: 45%;
  min-width: 380px;
  aspect-ratio: 0.75;
  max-height: 80vh;
  height: 80vh;
  background: #ffffff;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  color: #5624d0;
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .welcome-right-space {
    height: 40%;
    // height: fit-content;
    margin-left: 0px;
    width: 90%;
  }
}
