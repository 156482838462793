.user-profile {
  right: 7%;

  .demo-wrapper {
    border-radius: 12px;
    background-color: var(--page-content-background-color);

    .profile-section {
      padding: 24px;
      height: 128px;
      border-bottom: 1px solid var(--app-background-color);
      display: flex;

      .left-wrapper {
        height: 100%;
        width: 25%;

        img {
          height: 100%;
          aspect-ratio: 1;
          max-height: 80px;
          max-width: 80px;
          border-radius: 10px;
        }
      }

      .right-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        width: 75%;

        .user-name {
          width: 100%;
          font-weight: 600;
          font-size: 2.5rem;
          margin-bottom: 4px;
          line-height: 3.5rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .user-email {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .bottom-section {
      height: 70%;
      display: flex;
      flex-direction: column;

      .role-switching {
        width: 100%;
        padding: 5px 24px;

        .role-switching-head {
          font-size: 1.6rem;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .roles-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          background-color: var(--app-background-color);
          border-radius: 6px;

          .role {
            width: 50%;
            padding: 5px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .role:hover {
            cursor: pointer;
          }

          .active {
            background-color: var(--page-content-background-color);
            border-radius: 6px;
          }
        }
      }

      .dropdown-actions {
        display: flex;
        align-items: center;
        padding: 24px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        max-height: 5rem;
        text-decoration: none;
        color: inherit;
      }

      .logout {
        color: var(--delete-button-text-color);
      }

      .dropdown-actions:hover {
        cursor: pointer;
        background-color: var(--options-hover-background-color);
      }

      .disabledbutton {
        display: none;
      }
    }
  }
}

@media only screen and (orientation: portrait),
only screen and (max-width: 768px) {
  .user-profile {
    right: 0;

    .demo-wrapper {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      height: fit-content;

      .profile-section {
        .left-wrapper {
          max-width: 80px;
          width: 25%;

          img {
            width: 100%;
          }
        }

        .right-wrapper {
          width: 85%;
          margin-left: 1%;

          .user-name {
            font-size: 2rem;
          }

          .user-email {
            font-size: 1.4rem;
          }
        }
      }

      .bottom-section {
        height: 60%;
      }
    }
  }
}
