.org-list-container {
  padding: 1rem 0;
  height: fit-content;
  max-height: 400px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;
  .org-card {
    display: flex;
    align-items: center;
    padding: 1rem 2.4rem;
    transition: box-shadow;
    .org-image {
      width: 20%;
      min-width: 20%;
      height: 4rem;
      margin-right: 1rem;
      border-radius: 0.6rem;
      opacity: 0.8;
      object-fit: cover;
      border: 1px solid rgba($color: #000000, $alpha: 0.15);
    }
  }
  .org-card:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba($color: #333333, $alpha: 0.2);
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .org-list-container {
    height: fit-content;
    max-height: 200px;
    overflow: scroll;
    padding: 1rem 0;
    .org-card {
      padding: 1rem 1.2rem;
      .org-image {
        width: 20%;
        height: 3rem;
      }
    }
  }
}
