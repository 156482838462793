.CourseCardWrapper {
  height: 262px;
  border-radius: 12px;
  position: relative;
  width: 100%;

  .CourseCard {
    width: 100%;
    height: 100%;
    background-color: var(--page-content-background-color);
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    align-items: center;
    padding: 10px;

    .course-img {
      height: 50%;
      width: 100%;

      .card-link {
        text-decoration: none;
        color: inherit;

        img {
          width: 100%;
          height: 100%;
          border-radius: 12px;
          object-fit: cover;
        }
      }
    }

    .course-details {
      padding: 5px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      height: 50%;

      .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-height: 70px;
        overflow: hidden;
        text-overflow: ellipsis;

        .name {
          width: 70%;
          line-height: 2.4rem;

          .card-link {
            text-decoration: none;
            color: inherit;
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 1.2rem;

      .icons {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .CourseCard:last-of-type {
    margin-right: 0px;
  }

  .not-accessible {
    cursor: not-allowed;
    border-radius: 12px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    background-color: rgba($color: #000000, $alpha: 0.4);
    align-items: flex-end;
    display: none;

    .bottom-message {
      color: var(--over-button-font-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      width: 100%;
      height: 4rem;
      background-color: var(--course-inaccessible-banner-color);
    }
  }
}

.CourseCardWrapper:hover {
  .not-accessible {
    display: flex;
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .CourseCardWrapper {
    width: 100%;
    height: 140px;
    border-radius: 12px;

    .CourseCard {
      justify-content: space-between;
      padding: 8px;
      font-size: 1.2rem;
      height: 100%;
      width: 100%;

      .course-details {
        padding: 6px 0;
        margin-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;

        .header {
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 2.1rem;
          min-height: 2.1rem;

          .progress-hide {
            display: none;
          }
        }

        .footer {
          font-size: 1rem;

          .author {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            max-height: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .icons {
            * {
              margin-left: 0;
            }
          }
        }
      }
    }

    .not-accessible {
      display: flex;
    }
  }

  .admin-enrolled {
    height: 200px;
  }
}
