.choose-organization {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10%;
  width: 80%;

  .heading {
    text-align: center;
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }
  .choose-org-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 2.4rem;
    border-radius: 1.2rem;
    border: 1px solid rgba(215, 218, 225, 1);
    .search-org-container {
      margin: 0 2.4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 4.8rem;
      border: 1px solid rgba(215, 218, 225, 1);
      border-radius: 1.2rem;
      .search-org {
        border-radius: 1.2rem;
        border: none;
        padding: 1rem;
        height: 100%;
        flex-grow: 1;
        width: 80%;
      }
      .search-org:focus {
        outline: none !important;
      }
      .search-icon-wrapper {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media only screen and (orientation: portrait),
  only screen and (max-width: 768px) {
  .choose-organization {
    width: 100%;

    .heading {
      font-size: 2rem;
      margin-bottom: 2rem;
    }
    .choose-org-container {
      padding-top: 1.2rem;
      border-radius: 0.6rem;
      .search-org-container {
        margin: 0 1.2rem;
        height: 3rem;
        border-radius: 0.6rem;
        .search-org {
          border-radius: 0.6rem;
        }
      }
    }
  }
}
